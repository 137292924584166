html {
  display: flex;
}

body {
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
}

#root::before,
#root::after {
  content: "";
  position: fixed;
  left: 0;
  width: 100%;
}

#root::before {
  top: 0;
  background: linear-gradient(
    90deg,
    rgba(0, 179, 224, 1) 0%,
    rgba(131, 209, 97, 1) 75%,
    rgba(186, 221, 42, 1) 90%
  );
  z-index: 1202;
  height: 8px;
}

#root::after {
  bottom: 0;
  background: linear-gradient(
    90deg,
    rgba(0, 179, 224, 1) 0%,
    rgba(131, 209, 97, 1) 75%,
    rgba(186, 221, 42, 1) 90%
  );
  z-index: 1200;
  height: 4px;
}

@supports (height: max(0px)) {
  #root::before {
    height: max(8px, env(safe-area-inset-top));
  }

  #root::after {
    height: max(4px, calc(env(safe-area-inset-bottom) * 0.7));
  }
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#desktop-device {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#tablet-device {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#mobile-device {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#container-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#routes {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#routes > div {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.invalid-number-message {
  top: 7px !important;
  transform-origin: top left;
  left: 14px !important;
}

.react-tel-input.LIGHT .invalid-number-message {
  background-color: var(--light-mode-primary-color);
  color: var(--error-color);
}

.react-tel-input.DARK .invalid-number-message {
  background-color: var(--dark-mode-primary-color);
  color: var(--error-color);
}

.react-tel-input .special-label {
  top: 7px !important;
  transform-origin: top left;
  left: 14px !important;
}

/* adding asterix to outline it is required */
.react-tel-input .special-label:after {
  content: "*";
  right: 0;
  padding-left: 7px;
  color: var(--dark-mode-contrast-color);
}

.react-tel-input.LIGHT .special-label:after {
  color: var(--light-mode-contrast-color);
}

.react-tel-input.DARK .special-label:after {
  color: var(--dark-mode-contrast-color);
}

.react-tel-input.LIGHT .special-label {
  background-color: var(--light-mode-primary-color);
  color: rgba(0, 0, 0, 0.6);
}

.react-tel-input.DARK .special-label {
  background-color: var(--dark-mode-primary-color);
  color: rgba(255, 255, 255, 0.7);
}

.react-tel-input.no-special-label .special-label {
  display: none !important;
}


.react-tel-input.DARK .country-list .country.highlight {
  background-color: var(--dark-mode-secondary-active-color) !important;
}

.react-tel-input.DARK .country-list .country:hover {
  background-color: var(--dark-mode-secondary-active-color) !important;
}

.react-tel-input.LIGHT .country-list .country.highlight {
  background-color: var(--light-mode-secondary-active-color) !important;
}

.react-tel-input.LIGHT .country-list .country:hover {
  background-color: var(--light-mode-secondary-active-color) !important;
}

.react-tel-input .form-control {
  border-color: var(--input-border-color) !important;
  border-radius: 0px;
}

.react-tel-input .form-control:focus-visible {
  border-color: var(--input-border-color) !important;
}

.react-tel-input:not(:has(.invalid-number-message)) .phone-input:focus {
  box-shadow: none;
  outline: -webkit-focus-ring-color auto 1px;
}

.react-tel-input:has(.invalid-number-message) .form-control {
  box-shadow: 0px 0px 0px 1px var(--error-color);
}

.react-tel-input:has(.invalid-number-message) .form-control {
  color: var(--error-color) !important;
}

.carousel-theme--light .slick-active button::before {
  color: var(--light-mode-contrast-color) !important;
}
.carousel-theme--dark .slick-active button::before {
  color: var(--dark-mode-contrast-color) !important;
}

.slick-dots {
  bottom: 25px !important;
}

.custom-tree-select .ant-select-selection-placeholder {
  color: #9cadb3;
}

.ant-select-tree-treenode {
  width: max-content;
}

.light-tree-select .ant-select-arrow,
.light-tree-select .ant-select-clear {
  color: #757575;
}

.dark-tree-select .ant-select-arrow,
.dark-tree-select .ant-select-clear {
  color: #fff;
}

.custom-tree-select-error {
  border-color: var(--error-color);
}
.Toastify__toast-theme--LIGHT {
  background-color: var(--light-mode-primary-color);
}

.Toastify__toast-theme--DARK {
  background-color: var(--dark-mode-primary-dark-color);
}

.Toastify__toast-theme--LIGHT.Toastify__toast--success .Toastify__toast-body {
  color: var(--light-mode-contrast-color);
}

.Toastify__toast-theme--DARK.Toastify__toast--success .Toastify__toast-body {
  color: var(--dark-mode-contrast-color);
}

#toast-notification {
  bottom: 70px;
  padding: 0 10px;
}

.sql-keyword {
  color: #0000ff; /* Blue for SQL keywords */
  font-weight: bold;
}
.sql-operator {
  color: #ff0000; /* Red for SQL operators */
}
.sql-value {
  color: #008000; /* Green for SQL values */
}
.sql-field {
  color: #a52a2a; /* Brown for SQL fields */
}
.preformatted {
  white-space: pre-wrap; /* Preserve whitespace and wrap text */
  overflow-wrap: break-word; /* Break words if necessary to prevent overflow */
}


.queryBuilder {
  background-color: var(--query-builder-color);
}

.ruleGroup {
  background-color: var(--query-builder-color);
  padding: 20px;
  padding-right: 0;
  border: none;
  border-radius: 0;
  border-left: 2px solid #8081a2;
}

.ruleGroup-header {
  margin-top: 10px;
}

.rule {
  margin-top: 10px;
}

.ruleGroup-header .queryBuilder-dragHandle {
  order: 1;
}

.ruleGroup-header .MuiButtonBase-root {
  order: 2;
}

.ruleGroup-header .MuiFormControl-root {
  order: 3;
}

#remove-group-button {
  margin-left: auto !important;
  order: 4;
}

#remove-rule-button {
  margin-left: auto !important;
}

@media (max-width: 767px) {
  .image-icon {
    color: gray;
    width: 64px !important;
    height: 64px !important;
  }
  .top-element {
    /* margin-top: 25% !important; */
    margin-top: 112px !important;
  }

  .subscription-top-element {
    margin-top: 79px !important;
  }

  .pagePadding {
    padding-right: 32px;
    padding-left: 32px;
  }

  .submit-button {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .info-message {
    /* margin-top: 80% !important; */
    position: absolute;
    bottom: 114px;
    font-size: 12px !important;
    padding-right: 32px;
    padding-left: 32px;
  }

  .subscription-list-item {
    margin-left: 15px;
  }
}

.subscription-menu-item {
  padding: 16px 10px 16px 38px;
}

.subscription-description {
  padding-top: 0 !important;
}

.subscription-font {
  opacity: 1 !important;
  /* color: rgba(255, 255, 255, 1); */
  font-family: "Roboto-Bold" !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  font-style: normal !important;
  letter-spacing: 0, 15px !important;
  text-align: left !important;
  line-height: 20px !important;
}

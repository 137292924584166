:root {
  --light-mode-contrast-color: #0076bc;
  --dark-mode-contrast-color: #00B5E2;
  --light-mode-primary-color: #ffffff;
  --light-mode-primary-contrast-color: #000000;
  --light-mode-secondary-active-color: #EEEEEE;
  --dark-mode-primary-color: #141414;
  --dark-mode-primary-dark-color: #292929;
  --dark-mode-primary-contrast-color: #F4F4F4;
  --dark-mode-secondary-active-color: #3D3D3D;
  --error-color: #f44336;
  --input-border-color: #303030;
  --translate-parent-label: -100%;
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Cantarell";
  src: url("./fonts/Cantarell-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans";
  src: url("./fonts/FiraSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Oxygen";
  src: url("./fonts/Oxygen-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
li a {
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
  letter-spacing: 2px;
  font-size: 12px;

  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

li a:hover {
  color: #ffcb39;
}

.btn-go-home {
  position: absolute;
  z-index: 200;
  margin: 15px auto;
  width: 200px;
  padding: 10px 15px;
  border-radius: 100px;
  font-weight: 400;
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
  letter-spacing: 2px;
  font-size: 11px;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);

  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.btn-go-home.light {
  border: 1px solid var(--light-mode-contrast-color);
}

.btn-go-home.dark {
  border: 1px solid var(--dark-mode-contrast-color);
}

.btn-go-home:hover {
  color: #fff;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
}

.btn-go-home.light:hover {
  background-color: var(--light-mode-contrast-color);
}

.btn-go-home.dark:hover { 
  background-color: var(--dark-mode-contrast-color);
}

.wrap {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#svgWrap_1,
#svgWrap_2 {
  position: absolute;
  height: auto;
  width: 600px;
  max-width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#svgWrap_1,
#svgWrap_2 {
  animation: hueRotate 6s ease-in-out 3s infinite;
}

#id1_1,
#id2_1,
#id3_1 {
  stroke: #ff005d;
  stroke-width: 3px;
  fill: transparent;
  filter: url(#glow);
}

#id1_2,
#id2_2,
#id3_2 {
  stroke-width: 3px;
  fill: transparent;
  filter: url(#glow);
}

#id3_1 {
  stroke-dasharray: 940px;
  stroke-dashoffset: -940px;
  animation: drawLine3 2.5s ease-in-out 0s forwards,
    flicker3 4s linear 4s infinite;
}

#id2_1 {
  stroke-dasharray: 735px;
  stroke-dashoffset: -735px;
  animation: drawLine2 2.5s ease-in-out 0.5s forwards,
    flicker2 4s linear 4.5s infinite;
}

#id1_1 {
  stroke-dasharray: 940px;
  stroke-dashoffset: -940px;
  animation: drawLine1 2.5s ease-in-out 1s forwards,
    flicker1 4s linear 5s infinite;
}

@keyframes drawLine1 {
  0% {
    stroke-dashoffset: -940px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}

@keyframes drawLine2 {
  0% {
    stroke-dashoffset: -735px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}

@keyframes drawLine3 {
  0% {
    stroke-dashoffset: -940px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}

@keyframes flicker1 {
  0% {
    stroke: #ff005d;
  }
  1% {
    stroke: transparent;
  }
  3% {
    stroke: transparent;
  }
  4% {
    stroke: #ff005d;
  }
  6% {
    stroke: #ff005d;
  }
  7% {
    stroke: transparent;
  }
  13% {
    stroke: transparent;
  }
  14% {
    stroke: #ff005d;
  }
  100% {
    stroke: #ff005d;
  }
}

@keyframes flicker2 {
  0% {
    stroke: #ff005d;
  }
  50% {
    stroke: #ff005d;
  }
  51% {
    stroke: transparent;
  }
  61% {
    stroke: transparent;
  }
  62% {
    stroke: #ff005d;
  }
  100% {
    stroke: #ff005d;
  }
}

@keyframes flicker3 {
  0% {
    stroke: #ff005d;
  }
  1% {
    stroke: transparent;
  }
  10% {
    stroke: transparent;
  }
  11% {
    stroke: #ff005d;
  }
  40% {
    stroke: #ff005d;
  }
  41% {
    stroke: transparent;
  }
  45% {
    stroke: transparent;
  }
  46% {
    stroke: #ff005d;
  }
  100% {
    stroke: #ff005d;
  }
}

@keyframes hueRotate {
  0% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(-120deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}

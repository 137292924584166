.parent-tree-select-container {
  position: relative;
}

.parent-tree-select-label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.parent-tree-select-label-text {
  font-weight: 400;
  letter-spacing: 0.00938em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.parent-tree-select-label-text.light-text {
  color: rgba(0, 0, 0, 0.6);
}

.parent-tree-select-label-text.dark-text {
  color: rgba(255, 255, 255, 0.7);
}

.light-tree-select.ant-select-focused.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.75);
  border-color: #000000;
}

.dark-tree-select.ant-select-focused.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.75);
  border-color: white;
}

.ant-select:focus-within + .parent-tree-select-label,
.non-empty + .parent-tree-select-label {
  left: 5px;
}

.dark-tree-select:not(.ant-select-focused) .ant-select-selector {
  border: 1px solid rgba(255, 255, 255, 0.23) !important;
}

.ant-select:focus-within
  + .parent-tree-select-label
  .parent-tree-select-label-text,
.non-empty + .parent-tree-select-label .parent-tree-select-label-text {
  transform: translate(0, var(--translate-parent-label)) scale(0.75);
  padding-left: 4px;
  padding-right: 4px;
}

:not(.MuiDialog-container) .ant-select:focus-within + .dark-label .dark-text{
  background-color: var(--dark-mode-primary-color);
  color: var(--dark-mode-primary-contrastColor);
}

:not(.MuiDialog-container) .non-empty + .dark-label .dark-text {
  background-color: var(--dark-mode-primary-color);
}

.MuiDialog-container .ant-select:focus-within + .dark-label .dark-text{
  background-color: var(--dark-mode-primary-dark-color);
  color: var(--dark-mode-primary-contrastColor);
}

.MuiDialog-container .non-empty + .dark-label .dark-text {
  background-color: var(--dark-mode-primary-dark-color);
}

 .ant-select:focus-within + .light-label .light-text {
  background-color: var(--light-mode-primary-color);
  color: var(--light-mode-primary-contrast-color);
}

.non-empty + .light-label .light-text {
  background-color: var(--light-mode-primary-color);
}

.parent-tree-select-label .parent-tree-select-label-text {
  transition: all 200ms;
}

.ant-select-clear {
  inset-inline-end: 48px !important;
  top: 35% !important;
}

.ant-select-arrow {
  inset-inline-end: 8px !important;
}

.custom-tree-select .ant-select-selection-item {
  padding-inline-end: 48px !important;
}